import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
//import { Storage } from '@ionic/storage';
import { Storage } from '@capacitor/storage';
import { AdinekoService } from '../services/adineko.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

 
const USER_TOKEN = 'auth-token';
 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  authenticationState$ = new BehaviorSubject(false);
  
  
 
  constructor( 
    private router: Router, 
    //private storage: Storage, 
    private plt: Platform, 
    private adinekoService: AdinekoService 
    ) { 
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {  
    try {
      Storage.get( {key:USER_TOKEN}).then(res1 => {
        if (res1) {
          const res=JSON.parse(res1.value)
          if ( res1.value && res["expires_at"] !== undefined ){
            var expire=Date.parse(res["expires_at"])
            var now = new Date().getTime();
            if (expire > now ){
              this.authenticationState$.next(true);
            }else{
              this.authenticationState$.next(false);
            }
          }else{
            this.authenticationState$.next(false);
          }
        }else{
          this.authenticationState$.next(false);
        }
      })
    } catch (error) {
      this.authenticationState$.next(false);
    }
  }
 
  login( user, password) {

    this.adinekoService.login( user, password ).then((dat:any)=>{
      if ( dat ){
        return Storage.set( {key:USER_TOKEN, value: JSON.stringify(dat) } ).then(() => {
          this.authenticationState$.next(true);
        });
      }else{
        console.log("No valid auth");
        //this.adinekoService.presentToast("aaaaaaaaaaaaaaaaaaaa");
        this.authenticationState$.next(false);
      }
    });
  }
 
  logout() {
    console.log("auth service logout----");
    //this.checkToken();
    return Storage.remove({key:USER_TOKEN}).then(() => {
      this.router.navigate(['login']);
      this.authenticationState$.next(false);
    });
    Storage.remove({key:USER_TOKEN});
    Storage.remove({key:'ZAINTZAPEKO'});
    Storage.remove({key:'AZTERGAI'});
    
  }

  public getAuthStateObserver(): Observable<boolean> {
    return this.authenticationState$.asObservable();
  }

  public isAuthenticated() {
    return this.authenticationState$.value;
  }
 
 

  user_info(){
    return  new Promise( (resolve, reject) => {
      Storage.get({key:USER_TOKEN}).then(res1 => {
        if (res1) {
          const res=JSON.parse(res1.value)
          //console.log("CHECK:IIIII:" + JSON.stringify(res));
          //console.log("herria: " + res['herria']);
          resolve(res);
        }
      });
    });
  }
 
}