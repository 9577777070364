import { Injectable } from '@angular/core';

import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Subscriber, empty } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
//import { Storage } from '@ionic/storage';
import { Storage } from '@capacitor/storage';

import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';


const USER_TOKEN = 'auth-token';
const ZAINTZAPEKO = 'ZAINTZAPEKO';
const AZTERGAI = 'AZTERGAI';

@Injectable({
  providedIn: 'root'
})

/*


https://behari.basauri.eus/login 

helbidean:
user: behari@gislan.eus
pasahitza: Probatzen9?



user: ainize@netkam.com erabiltzailearen_gakoa: Probatzen
user: gilberto@gislan.eus erabiltzailearen_gakoa: Probatzen


1) Login geiteko helbidea, bertan Tokena bueltatuko da Mugikorrari.
url=http://behari.netkam.site:7021/public/api/auth/login
Bidali beharreko parametroak:
Array ( [email] => ainize@netkam.com [erabiltzailearen_gakoa] => Probatzen ) 
Erantzuna:
{"result":1,"id_user":25,"access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNiNTk3M2I5YjQ3ZTU4NTVhMjY2NzZkNTRjZmE1Nzg2M2FlMGI3OWQzYTQ2YTE4OTk0MzBjNWEyMzk1NWYyMDFiZWE0MWRmZTFkMDhlN2EyIn0.eyJhdWQiOiIxIiwianRpIjoiY2I1OTczYjliNDdlNTg1NWEyNjY3NmQ1NGNmYTU3ODYzYWUwYjc5ZDNhNDZhMTg5OTQzMGM1YTIzOTU1ZjIwMWJlYTQxZGZlMWQwOGU3YTIiLCJpYXQiOjE1OTQyMDE5MjYsIm5iZiI6MTU5NDIwMTkyNiwiZXhwIjoxNTk0MjAzNzI2LCJzdWIiOiIyNSIsInNjb3BlcyI6W119.H6nzXxPtHIMG0oOLBr-wovgrpeUW05JQaX1A3x1nxErvjQ70HEtWNKOwO11hcpA2_ii5xAf5zLxC0En7HjF4Jo9GEn79nPWuRtDqak9-lY8PCFqDS_wlnsGsagT7BeDctb_b6qHgwKFH9LQI27L-2QkkUGW3VB85ATz-LZIiPPf3aY135uW7WfhBC_FAgdqbweXXYkQaRrfHBbls-UItetqU0wTNRbT-INhEG4PrsQydHfzWLiAyjAJ0EbB6wbDDKXBxpngXIiLuKXisyRU5JFd9qEQGFuZQySiLfx6J7GPAoTGyAhslqyPJ7DUuSW1NUEWcgk-uJYOgA7mobb40WB2c1Ywe_byKqIhOGbAYxCwtbR9ozhGtBiheQTCrnlXPnm8eTpo0-PBp3-yvMVb5C-LydQugNbld7P49eSNAXAAa3fZMLmwwaVgBZRB-bVEOWjiXKzFR4gj4Fe8anct4S6BkBhYi_n62gzwgrSOaHalB673cp8k2GKbltAMlbdFXdXJQTa3IM5ZChFGk4aCDfqFBKM1tx7gT_Xe6lX4TJlL5ubaZf3dLBm3bFYEIVBI3TLN63QfNxwl9NktlSH8hpnrORGR-ZGbafNx1JTQe-kKZrmHMPRYYBqqdK4A-rDZHZ6B0XSPY748XzNlnF3cv8ElaeIgKY8JCIysGxqkKTYs","token_type":"Bearer","expires_at":"2020-07-08 12:22:06"}
Erantzunean begiratu beharrekoa result=1 izatea
"access_token" Beste Api deietako gordeko dugu, 30 minututan kadukatzen da.
"id_user" ere gorde memorian hurrengo Api deietan erabiltzeko.


3) Behari horrek, zaindu beharreko pertsona zerrenda eskuratzeko:
url=http://behari.netkam.site:7021/public/api/auth/mugikor-api/persona-programa-behari-zerrenda
Bidali beharreko parametroak:
Alde batetik headerrean lehen lortu dugun Tokena
Beste alde batetik Post eremu bezela, lehen lortu dugun id_user-a
Array ( [id_user] => 25 ) 
Erantzun zuzena:
{"result":1,"persona_programa_behari_array":[{"dboid_person":"2000400900095913107208","nombre_apellidos":"ALBERTO SANCHEZ RUIZ","barrio":"Kareaga","tipo_via":"AV","nombre_via":"AGIRRE LEHENDAKARIA","portal":1,"bis":" ","piso":"10","puerta":"IZ--"},{"dboid_person":"2000400900093785707208","nombre_apellidos":"ANTONIA RODRIGUEZ SANCHEZ","barrio":"Matadero","

Erantzuna errorearekin:
{"result":0,"beharia_load_type":"","message":""}

4) Load options
url=http://behari.netkam.site:7021/public/api/auth/mugikor-api/options-load
Bidali beharreko parametroak:
Alde batetik headerrean lehen lortu dugun Tokena
Beste alde batetik Post eremu bezela, lehen lortu dugun id_user-a
Array ( [id_user] => 25 ) 
Erantzun zuzena:
{"result":1,"taldeak":[{"id_talde":1,"talde":"Salud y cuidado","aztergaiak_options":{"5":"Adicciones","3":"Caidas","2":"Cambios de salud","4":"Deficit sensorial y problemas de movilidad","1":"Enfermedad terminal","6":"Patologia fisica o mental con inadecuado seguimiento medico","7":"Presencia fisica"}},{"id_talde":2,"talde":"Estado cognitivo","aztergaiak_options":{"10":"Dificultades de comunicación","8":"Olvidos significativos, desorientacion","9":"Perdida de competencia"}},{"id_talde":3,"talde":"Estado emocional","aztergaiak_options":{"16":"Cambios en el estado de animo","18":"Duelo","17":"Muestra de soledad","19":"Sobrecarga en el cuidado"}},{"id_talde":4,"talde":"Aspectos relacionales","aztergaiak_options":{"23":"Aislamiento social","21":"Conflictos relacioneles","22":"Falta de apoyo familiar o comunitario","20":"Viudedad reciente"}},{"id_talde":5,"talde":"Vivienda y organizacion domestica","aztergaiak_options":{"25":"Dificultad de acceso al exterior","26":"Higiene y mantenimiento deficiente","34":"Pérdida de vivienda","27":"Problema economicos y escasez de recursos","24":"Vivienda degradada"}},{"id_talde":6,"talde":"Alerta inmediata","aztergaiak_options":{"30":"Cansancio vital y comunicacion de deseo de muerte","32":"Riesgo de accidente grave en la vivienda","29":"Riesgo grave para la integridad fisica y la seguridad de la persona","28":"Riesgo grave para la salud","31":"Victima de malos tratos o violencia de genero"}}]}
Erantzuna errorearekin:
{"result":0,"message":"","error_msg":"Error de validacion: options_load"}

5) Pertsonaren adierazleak store edo gorde
url=http://behari.netkam.site:7021/public/api/auth/mugikor-api/persona-adierazleak-store
Bidali beharreko parametroak:
Alde batetik headerrean lehen lortu dugun Tokena
Beste alde batetik Post eremu bezela, lehen lortu dugun id_user-a
Array ( [id_user] => 31 [dboid_person] => 2000400900094481007208 [aztergaiak] => json_encode(array(3,21)) )
Erantzun zuzena:
{"result":1,"message":""}
Erantzun errorearekin:
{"result":0,"beharia_load_type":"","message":""}

*/


export class AdinekoService {


  //probetan
  //getApiUrl : string = "http://behari.netkam.site:7021/santurtzi/public/api/auth";
  //sendApiUrl : string = "http://behari.netkam.site:7021/santurtzi/public/api/auth";

  //Demo
  //getApiUrl : string = "http://behari.netkam.site:7022/public/api/auth";
  //sendApiUrl : string = "http://behari.netkam.site:7022/public/api/auth";
  

  //Basauri lanean
  //getApiUrl : string = "https://behari.basauri.eus/public/api/auth";
  //sendApiUrl : string = "https://behari.basauri.eus/public/api/auth";


  //Santurtzi
  getApiUrl : string = "https://adinberri.teknoadineko.eus/public/api/auth";
  sendApiUrl : string = "https://adinberri.teknoadineko.eus/public/api/auth";
  
  zaintzapeko: any = {}
  location: any;
  

  constructor(
    public http: HttpClient,
    //private storage: Storage,
    public toastController: ToastController,
    public alertController: AlertController,
    private route: ActivatedRoute, 
    private router: Router,
    //private languageService: LanguageService,


  ) { 
    
  }


  private getRemoteData( data2post, token=null ){
    //this.sendQueue();

    let header = {
      "Accept": 'application/json',
      "Content-Type": 'application/json'
    }
    var httpOptions={}
    if( token ){
      header['Authorization']='Bearer ' + token
    }
    httpOptions = { headers: new HttpHeaders(header)  };
    
    if (typeof data2post.cmd  === 'undefined') {
      console.log("Wrong cmd. ")
      return null
    }
    let cmd=data2post['cmd'];
    delete(data2post['cmd']);

    let url=this.getApiUrl
    
    if (url.substr(-1) != '/') url += '/';
    if ( cmd.substr(0,1) == '/' ) cmd = cmd.substr(1);
    url += cmd;
    
    
    //console.log("*URL::::: " + url );
    //console.log("DATA::::: " + JSON.stringify(data2post) );
    //console.log("headers::::: " + JSON.stringify(header) );
    //console.log("options::::: " + JSON.stringify(httpOptions) );
    
    return  new Promise( (resolve, reject) => {
        this.http.post( url, data2post, httpOptions ).subscribe((rdata) => {
          //console.log("jaso::: " + JSON.stringify(rdata))
          resolve(rdata);
        },
      err => {
        //console.log("*ERROR::: " + JSON.stringify(err))
        if( err.error['message'] !== undefined ){
          this.presentToast( err.error['message'] );
        }
        else if( err.message !== undefined ){
          this.presentToast( err.message );
        }
        if ( err.status == 401 ){
          this.router.navigate(['login']);
        }
        resolve(err.error);
      })
    });
  }

  /*async addQueue(data2send){
    this.storage.ready().then(() => { 
      this.storage.keys().then( (k:any) => {
        if ( k.includes('SEND_QUEUE') ) {
          this.storage.get('SEND_QUEUE').then((queue) => {
            queue.push(data2send)
            this.storage.set('SEND_QUEUE', queue );
          });
        }else{
          var queue=[];
          queue.push(data2send)
          this.storage.set('SEND_QUEUE', queue );
        }
      });
    });
  }*/

  /*async sendQueue(){
    this.storage.get('SEND_QUEUE').then((queue) => {
      if ( queue != undefined ){
        this.storage.remove('SEND_QUEUE').then( () =>{ 
          for ( let i = 0, p = Promise.resolve(); i < queue.length; i++ ){
            let p1 = p.then(_ => new Promise( (resolve:any) => {
              this.sendData( queue[i] ).then( (rv:any) =>{
                resolve(rv);
              });
            }));
          }
        });
      }
    });
  }*/

  /*sendData( data2send_d ){
    this.sendQueue();

    let headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );
   
    if (typeof data2send_d.cmd  === 'undefined') {
      console.log("Wrong cmd. ")
      return null
    }
    
    let cmd=data2send_d.cmd;
    delete(data2send_d['cmd']);

    let url=this.getApiUrl
    if (url.substr(-1) != '/') url += '/';
    url += cmd;

    console.log("URL::::: " + url );
    console.log("DATA::::: " + JSON.stringify(data2send_d) );

    return  new Promise( (resolve, reject) => {
      this.http.post(url, data2send_d ).subscribe((response) => {
        //console.log(response);
        this.presentToast();
        resolve(true);
      }, err => {
        console.log("Error SEND http get ")
        console.log(err);
        this.addQueue(data2send_d).then(()=>{
          resolve(false);
        });
        this.presentAlert("Error SEND http");  
      });
    });
  }*/

  async presentToast(msg='Datuak zuzen bidali dira.') {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000
    });
    toast.present();
  }

  async presentAlert(e) {
    const alert = await this.alertController.create({
      header: 'Alert',
      subHeader: 'Errorea',
      message: 'Errorea datuak bidaltzean.',
      buttons: ['OK']
    });
  }

  public login( user, password ) {
    
    return  new Promise( (resolve2, reject) => {
      let data2send= { cmd: 'login', "email":user, "erabiltzailearen_gakoa": password };
      var r=this.getRemoteData(data2send).then((resolve:any)=>{
        if( resolve['result'] !== undefined && resolve['result']==1  ){
          Storage.set({key: USER_TOKEN, value: JSON.stringify(resolve)} );
          Storage.remove({key:'ZAINTZAPEKO'});
          Storage.remove({key:'AZTERGAI'});
          resolve2(resolve);
        }else{
          console.log("Wrong Auth..........")
          Storage.remove({key: USER_TOKEN});
          Storage.remove({key:'ZAINTZAPEKO'});
          Storage.remove({key:'AZTERGAI'});
          
          resolve2(false);
        }
      });
    });
  }

  public zaintzapekoFilterItems ( ){
    return  new Promise( (resolve2, reject) => {
      Storage.get( {key: USER_TOKEN } ).then((user1) => {
        
        if (user1) {
          const user=JSON.parse(user1.value);
          let data2send2={ "cmd": "/mugikor-api/persona-programa-behari-zerrenda", "id_user": user['id_user'] };
          var r=this.getRemoteData(data2send2, user["access_token"]).then((resolve:any)=>{
            if( resolve['result'] !== undefined && resolve['result'] == 1 ){
              this.zaintzapeko=resolve['persona_programa_behari_array'];
              Storage.get( { key:ZAINTZAPEKO }) .then((els) => {
                /*if ( els == null ){
                  els={};
                }else{
                const els1=JSON.parse(els.value)*/
                const els2=resolve['persona_programa_behari_array'];
                Storage.set( {key:ZAINTZAPEKO, value: JSON.stringify(els2) });
              });
              resolve2(this.zaintzapeko);
            }else{
              Storage.get( {key: ZAINTZAPEKO }).then((l) => {
                resolve2( JSON.stringify(l.value) );
              });
            }
          });
        }
      });
    });
  }

  public zaintzapekoFilterItem ( codigo ){
    return  new Promise( (resolve2, reject) => {
      Storage.get({key:ZAINTZAPEKO}).then((zaintzapeko1) => {
        if ( zaintzapeko1 != undefined  ){
          const zaintzapeko=JSON.parse(zaintzapeko1.value)
          for( var i in zaintzapeko ){
            if ( zaintzapeko[i]['codigo'] == codigo ){
              resolve2(zaintzapeko[i]);
              break;
            }
          }
        }
      });
    });
  }

  public aztergaiak ( ){
    return  new Promise( (resolve2, reject) => {
      Storage.get({key:USER_TOKEN}).then((user1) => {
        if (user1) {
          const user=JSON.parse(user1.value)
          let data2send2={ "cmd": "/mugikor-api/options-load", "id_user": user['id_user'] };
          var r=this.getRemoteData(data2send2, user["access_token"] ).then((resolve:any)=>{
            if( resolve['result'] !== undefined && resolve['result'] == 1 ){
              var aztergaiak=resolve['taldeak'];
              Storage.get({key:AZTERGAI}).then((els) => {
                Storage.set({key:AZTERGAI, value: JSON.stringify(aztergaiak) });
              });
              resolve2(aztergaiak);
            }else{
              Storage.get({key:AZTERGAI}).then((l) => {
                resolve2(JSON.parse(l.value));
              });
            }
          });
        }
      });
    });
  }

  public send_egoera ( dboid_person, aztergaiak ){
    return  new Promise( (resolve2, reject) => {
      Storage.get( {key:USER_TOKEN } ).then((user1) => {
        if (user1) {
          const user=JSON.parse(user1.value)
          let data2send2={ "cmd": "/mugikor-api/persona-adierazleak-store", "dboid_person":dboid_person, "id_user": user['id_user'], "aztergaiak": JSON.stringify(aztergaiak) };
          this.getRemoteData(data2send2, user["access_token"]).then((resolve:any)=>{
            if( resolve['result'] == undefined || resolve['result'] != 1 ){
              this.presentToast(resolve['message'] );
              resolve2(resolve['result']);              
            }else{
              resolve2({});
              console.log("zuzena......")
            }
          });
        }
      });
    });
  }

  



  

}
